import "@/styles/float.scss";
import { getAssets } from "@/utils/helpers";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-scroll";
import useGAEventsTracker from "../hooks/useGAEventsTracker";
import { useDimensionTracker } from "@/hooks/useDimensionsTracker.js";

const Float = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const ids = [
    "",
    "Home",
    "AboutUs",
    "Esports",
    "Lootbox",
    "WhyChoose",
    "Events",
    "Contact",
  ];
  const GAEventsTracker = useGAEventsTracker("Demo");
  const GAEContactTracker = useGAEventsTracker("To Know More");
  const dimensions = useDimensionTracker();
  const navigateToContacts = () => {
    navigate("");
  };
  const { pathname } = useLocation();
  if (pathname !== "/") return null;

  return (
    <div>
      {dimensions > 480 ? (
        <div className="float__Content">
          <div className="float__Content-desktop">
            <div className="float__Content-desktop-img">
              <img
                src={require("@/" + getAssets("floating-bg.png", false, false))}
                alt="float-bg"
              />
            </div>
            <div className="float__Content-desktop-demo_url">
              <a
                href="/demo"
                rel="referrer"
                target="_blank"
                onClick={(e) => {
                  GAEventsTracker("Demo");
                  navigateToContacts();
                }}
              >
                {t("View demo")}
              </a>
            </div>

            <div className="float__Content-desktop-to-know-more">
              <Link
                smooth
                href="#Contact"
                to={`${ids[7]}`}
                spy
                offset={-65}
                ignoreCancelEvents={false}
                onClick={(e) => GAEContactTracker("Contact Us")}
              >
                {t("To know more")}
              </Link>
            </div>
          </div>
        </div>
      ) : (
        dimensions <= 480 && (
          <div className="float_mobile">
            <div className="float_mobile-icon">
              <div className="float_mobile-icon-demo_url">
                <a
                  href="/demo"
                  rel="noreferrer"
                  target="_blank"
                  onClick={(e) => GAEventsTracker("Demo")}
                >
                  <div>
                    <img
                      src={require("@/" + getAssets("demo.png", true))}
                      alt="demo"
                    />
                  </div>
                </a>
              </div>

              <div className="float_mobile-icon-to-know-more">
                <Link
                  smooth
                  href="#Contact"
                  to={`${ids[7]}`}
                  spy
                  offset={10}
                  ignoreCancelEvents={false}
                  onClick={(e) => GAEContactTracker("Contact Us")}
                >
                  <div>
                    <img
                      src={require("@/" + getAssets("contact.png", true))}
                      alt="contact"
                    />
                  </div>
                </Link>
              </div>
            </div>
          </div>
        )
      )}
    </div>
  );
};

export default Float;
